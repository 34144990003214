<template>
    <v-container class="page-container" fill-height fluid grid-list-xl
    >
        <v-layout wrap id="configuration">
          <h1 class="page-title text-xs-left mb-3 w-100">Treatments</h1>
          <div class="flex-display flex-row flex-justify-between mb-3 w-100">
              <input class="search-container" v-model="searchTreatment" placeholder="Search for an treatment area…">
              <button class="btn add-new" @click="showTreatmentModal('add', null)"><i class="v-icon mdi mdi-plus"></i> New Treatment</button>
          </div>
          <div class="item-container"
               v-for="t in filterTreatments"
               :key="t.id"
          >
              <p class="item-text">{{t.name}}</p>
              <div class="item-actions">
                    <button class="btn-edit" @click="showTreatmentModal('edit', t)">Edit</button>
                    <button class="btn-delete ml-2" @click="deleteConfirmation(t)">Delete</button>
              </div>
          </div>

          <!-- Add Treatment-->
          <add-treatment v-if="addTreatmentDialog"
                         :visible="addTreatmentDialog"
                         :action-type="action"
                         :value="selectedTreatment"
                         v-on:addTreatment="getTreatments"
                         v-on:hide="hideTreatmentModal"
          >
          </add-treatment>

          <!-- delete dialog -->
          <confirmation v-if="deleteDialog" :visible="deleteDialog">
                <template slot="title">
                    Are you sure?
                </template>
                <template slot="desc">
                    This Treatment will be deleted. This action cannot be undone.
                </template>
                <template slot="actions">
                    <a class="cancel-model" @click="hideConfirmation">Cancel</a>
                    <button class="btn modal-action-button confirmation-delete" @click="DeleteTreatment">Delete</button>
                </template>
          </confirmation>
        </v-layout>

    </v-container>
</template>

<script>
    import Confirmation from "../components/common/Confirmation";
    import AddTreatment from "../components/configuration/addTreatment";
    export default {
        components: {Confirmation, AddTreatment},
        data: () => ({
             treatments: [],
             selectedTreatment: null,
             searchTreatment: '',
             deleteDialog: false,
             addTreatmentDialog: false,
             action : 'add',
             activeTreatment: 0
        }),
        computed: {
            filterTreatments() {
                let treatments = this.treatments;
                if (this.searchTreatment) {
                    treatments = treatments.filter((p) => {
                        return p.name.toLowerCase().indexOf(this.searchTreatment.toLowerCase()) !== -1;
                    });
                }
                return treatments;
            },
        },
        created(){
           this.getTreatments();
           this.resetSelectedTreatment();
        },
        methods:{
            getTreatments(){
                let self = this;
                let loader = this.$loading.show();
                self.hideTreatmentModal();
                this.$http.get(this.url+'treatments', {
                }).then(function (response) {
                    loader.hide();
                    self.treatments = response.data.data;
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message, 'Error');
                })
            },
            deleteConfirmation(treatment) {
                this.deleteDialog = true;
                this.activeTreatment =  treatment;
            },
            hideConfirmation () {
                this.deleteDialog = false;
                this.activeTreatment = 0 ;
            },
            DeleteTreatment(){
                let self = this;
                self.deleteDialog = false;
                let loader = this.$loading.show();
                this.$http.delete(this.url+'delete_treatment', {
                    data: {
                        id: this.activeTreatment.id
                    }
                }).then(function (response) {
                    loader.hide();
                    self.$toastr('success', response.data.message);
                    self.getTreatments();
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error',error.response.data.message);
                })
            },
            showTreatmentModal(action, value){
                this.addTreatmentDialog = true;
                this.action = action;
                this.selectedTreatment  = value;
            },
            hideTreatmentModal(){
                this.addTreatmentDialog  = false;
                this.resetSelectedTreatment();
            },
            resetSelectedTreatment() {
                this.selectedTreatment = {
                    code: '',
                    name: '',
                    description: '',
                    blogId: null,
                    searchable: 0
                }
            }

        }
    }
</script>
